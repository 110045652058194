import * as React from "react"
import { Link, PageProps } from "gatsby"
import { 
  componentResolverFromMap,
  withPrismicUnpublishedPreview
} from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/linkResolver"
import { GatsbyTransitionLinkProps } from "../types"
import { useTransitionParameters } from "../hooks/useTransitionParameters"

import IndexTemplate from "../templates/index"
import PageTemplate from "../templates/page"
import ProjectTemplate from "../templates/project"

const NotFoundPage = ({
  entry,
  exit,
  transitionStatus,
}: PageProps & GatsbyTransitionLinkProps) => {
  const {
    mainTransitionClasses,
    mainTransitionStyles,
  } = useTransitionParameters({
    entry: entry,
    exit: exit,
    transitionStatus: transitionStatus,
  })

  return (
    <div className={mainTransitionClasses} style={mainTransitionStyles}>
      <div className="text w-full h-screen flex items-center justify-center">
        <p>
          Page not found, <Link to="/">go home</Link>.
        </p>
      </div>
    </div>
  )
}

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withPrismicUnpublishedPreview(NotFoundPage, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY ?? ``,
  linkResolver,
  componentResolver: componentResolverFromMap({
    index: IndexTemplate,
    page: PageTemplate,
    project: ProjectTemplate,
  }),
}])
