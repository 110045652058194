import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { graphql, PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { GatsbyTransitionLinkProps, PageContext } from "../types"
import { linkResolver } from "../utils/linkResolver"
import TransitionLink, {
  useTriggerTransition,
} from "gatsby-plugin-transition-link"
import { useTransitionParameters } from "../hooks/useTransitionParameters"
import { GatsbyImage } from "gatsby-plugin-image"
import { RichText } from "prismic-reactjs"
import { PrismicLink } from "../components/prismicLink"
import { resizeImage } from "../utils/resizeImage"

function isProjectSlice(
  slice?: GatsbyTypes.Maybe<GatsbyTypes.PrismicIndexDataBodySlicesType>
): slice is GatsbyTypes.PrismicIndexDataBodyProject {
  return slice?.slice_type === `project`
}

function isTextSlice(
  slice?: GatsbyTypes.Maybe<GatsbyTypes.PrismicIndexDataBodySlicesType>
): slice is GatsbyTypes.PrismicIndexDataBodyText {
  return slice?.slice_type === `text`
}

function isImageSlice(
  slice?: GatsbyTypes.Maybe<GatsbyTypes.PrismicIndexDataBodySlicesType>
): slice is GatsbyTypes.PrismicIndexDataBodyImage {
  return slice?.slice_type === `image`
}

function isProject(
  document?: GatsbyTypes.Maybe<GatsbyTypes.PrismicAllDocumentTypes>
): document is GatsbyTypes.PrismicProject {
  return document?.type === `project`
}

const IndexTemplate = (props: PageProps<{ prismicIndex: GatsbyTypes.PrismicIndex }, PageContext> & GatsbyTransitionLinkProps): JSX.Element => {
  const isHomepage = props?.location?.pathname === `/`,
    index = props?.data?.prismicIndex

  if (!index) return <></>
  
  const body = index?.data?.body,
    projects = body?.filter(
      (slice) => isProjectSlice(slice)) as GatsbyTypes.PrismicIndexDataBodyProject[] | undefined,
    firstProject = projects && projects[0] && projects[0].primary?.project?.document as GatsbyTypes.PrismicProject | undefined,
    resizedFirstProjectImage = resizeImage(firstProject?.data?.image?.gatsbyImageData)

  // Force render if projects have missing documents
  // (because preview content has not been proxied)
  const [update, setUpdate] = useState(false)
  useEffect(() => {
    const projectsHaveMissingIds = projects?.some(slice => slice?.primary?.project?.id === null),
      projectsHaveMissingDocuments = projects?.some(slice => isProjectSlice(slice) && !slice?.primary?.project?.document)
    if(!projectsHaveMissingIds && projectsHaveMissingDocuments && !update) setUpdate(true)
  })

  const {
    mainTransitionClasses,
    mainTransitionStyles,
    transitionLinkProps
  } = useTransitionParameters({
    entry: props?.entry,
    exit: props?.exit,
    transitionStatus: props?.transitionStatus,
  })

  return (
    <div className={mainTransitionClasses} style={mainTransitionStyles}>
      {!isHomepage && (
        <Helmet>
          <title>{index?.data?.title}</title>
          <meta name="twitter:title" content={index?.data?.title} />
          <meta property="og:title" content={index?.data?.title} />
        </Helmet>
      )}
      <Helmet>
        <meta name="twitter:image" content={resizedFirstProjectImage.src} />
        <meta property="og:image" content={resizedFirstProjectImage.src} />
        <meta property="og:image:width" content={`${resizedFirstProjectImage.width}`} />
        <meta property="og:image:height" content={`${resizedFirstProjectImage.height}`} />
      </Helmet>
      <div className="flex flex-wrap pt-20 mx-auto w-100 md:w-full -max-w-16 md:max-w-4xl lg:max-w-5xl xl:max-w-6xl justify-between">
        {body?.map((slice) => {
          if (isProjectSlice(slice)) {
            const project = slice?.primary?.project,
              document = project?.document

            if (!isProject(document)) return null

            const uid = project?.uid,
              index =
                projects?.findIndex(
                  indexSlice => slice.id === indexSlice?.id
                ) ?? 0,
              data = document?.data

            return (
              <div
                key={slice.id}
                className="flex items-center w-100 my-20 md:my-40"
              >
                <TransitionLink
                  className="relative group w-full text-lg no-underline transform scale-100 motion-safe:hover:scale-98 transition-transform duration-200"
                  to={`/projects/${uid}`}
                  {...transitionLinkProps}
                >
                  <div className="md:absolute pb-3 opacity-100 md:opacity-0 group-hover:opacity-100 duration-200 md:transform md:-translate-y-full transition-opacity">
                    {`${index + 1}`.padStart(3, `0`)}
                  </div>
                  {data?.image?.gatsbyImageData && (
                    <GatsbyImage
                      alt={data?.image?.alt ?? ``}
                      className="w-full opacity-100 group-hover:opacity-95 transition-opacity duration-200"
                      image={data?.image?.gatsbyImageData}
                      sizes="(max-width: 396px) calc(100vw - 96px), 300px"
                    />
                  )}
                  <div className="md:absolute mt-4 opacity-100 md:opacity-0 group-hover:opacity-100 duration-200">
                    <h1 className="inline font-bold uppercase">
                      {data?.title}
                    </h1>
                    {data?.year && `, ${data.year}`}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.materials?.html ?? ``,
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.dimensions?.html ?? ``,
                      }}
                    />
                  </div>
                </TransitionLink>
              </div>
            )
          } else if (isTextSlice(slice)) {
            return (
              <div
                key={slice.id}
                className={`text flex flex-col items-center justify-center w-100 my-20 md:my-40 ${
                  slice?.primary?.size === `Large` ? `text-lg` : ``
              }`}>
                <RichText
                  render={slice?.primary?.text?.raw}
                  serializeHyperlink={PrismicLink}
                />
              </div>
            )
          } else if (isImageSlice(slice)) {
            const imageContent = slice?.primary?.image?.gatsbyImageData && (
                <GatsbyImage
                  alt={slice?.primary?.image?.alt ?? ``}
                  className="w-full opacity-100 group-hover:opacity-95 transition-opacity duration-200"
                  image={slice?.primary?.image?.gatsbyImageData}
                  sizes="(max-width: 396px) calc(100vw - 96px), 300px"
                />
              ),
              link = slice?.primary?.link,
              linkType = link?.link_type,
              linkContent =
                linkType === `Document` ? (
                  <TransitionLink
                    className="group w-full text-lg no-underline transform scale-100 motion-safe:hover:scale-98 transition-transform duration-200"
                    to={linkResolver(link)}
                    {...transitionLinkProps}
                  >
                    {imageContent}
                  </TransitionLink>
                ) : link?.url ? (
                  <a
                    className="group w-full text-lg no-underline transform scale-100 motion-safe:hover:scale-98 transition-transform duration-200"
                    href={link?.url ?? ``}
                    target={link?.target ?? ``}
                    rel="noopener"
                  >
                    {imageContent}
                  </a>
                ) : (
                  <>{imageContent}</>
                )
            return (
              <div
                key={slice.id}
                className="flex items-center w-100 my-20 md:my-40"
              >
                {linkContent}
              </div>
            )
          } else {
            return null
          }
        })}
      </div>
    </div>
  )
}

const IndexTemplateWithPreview = withPrismicPreview(IndexTemplate, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY ?? ``,
  linkResolver,
}])

export default IndexTemplateWithPreview

export const indexQuery = graphql`
  query IndexBySlug($uid: String!) {
    prismicIndex(uid: { eq: $uid }) {
      ...PrismicIndex
    }
  }
`

export const prismicIndexFragment = graphql`
  fragment PrismicIndex on PrismicIndex {
    _previewable
    data {
      body {
        ... on PrismicIndexDataBodyProject {
          id
          primary {
            project {
              id
              uid
              document {
                ... on PrismicProject {
                  _previewable
                  id
                  data {
                    dimensions {
                      html
                    }
                    image {
                      gatsbyImageData
                      alt
                    }
                    materials {
                      html
                    }
                    title
                    year
                  }
                  type
                }
              }
            }
          }
          slice_type
        }
        ... on PrismicIndexDataBodyText {
          id
          primary {
            size
            text {
              html
              raw
              text
            }
          }
          slice_type
        }
        ... on PrismicIndexDataBodyImage {
          id
          primary {
            image {
              gatsbyImageData
              alt
            }
            link {
              link_type
              uid
              url
              target
              type
            }
          }
          slice_type
        }
      }
      title
    }
    id
    prismicId
    type
    uid
  }
`
